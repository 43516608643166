import React, { Component } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { GridOverlay } from "@material-ui/data-grid";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@mui/material/Skeleton";
import AppSettings from "app-settings";
import NoResultHelper from "../helper/NoResultHelper.jsx";
import OrderActionResponsePopupHelper from "../helper/OrderActionResponsePopup.jsx";
import "@lottiefiles/lottie-player";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//import { ReactComponent as AddProductIcon } from "../../assets/img/ctaIcons_AddProduct.svg";
import { ReactComponent as DeleteOrdersIcon } from "../../assets/img/ctaIcons_Delete.svg";
//import { ReactComponent as ExportCSVIcon } from "../../assets/img/ctaIcons_ExportCSV.svg";
//import { ReactComponent as ImportCSVIcon } from "../../assets/img/ctaIcons_ImportCSV.svg";
import { ReactComponent as SortByIcon } from "../../assets/img/icons_SortBy.svg";
import { ReactComponent as AssemblyIcon } from "../../assets/img/productsIcon_Assembly.svg";
import APIService from "../../services/APIService.js";
import Help from "../Help/Help.jsx";
import ApiResponseError from "../helper/ApiResponseError.jsx";
import APIResponsePopupHelper from "../helper/APIResponsePopupHelper.jsx";
import CheckBoxHelper from "../helper/CheckBoxHelper.jsx";
import ConfirmationPopup from "../helper/ConfirmationPopup.jsx";
import LoadingHelper from "../helper/LoadingHelper.jsx";
import Pagination from "../helper/PaginationHelper.jsx";
import SearchBarHelper from "../helper/SearchBarHelper.jsx";
import AddEditProduct from "./AddEditProduct.jsx";
import EditDeleteDropdown from "./EditDeleteDropdown.jsx";
import "./ProductsPage.scss";

const { innerHeight: window_height } = window;
const dataGridHeight = window_height - 230;
const styles = () => ({
  dataGrid: {
    height: dataGridHeight + "px",
    overflow: "auto",
  },
  customTableContainer: {
    overflowX: "initial",
  },
});

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    props.getHelp(Help.ProductPage);

    this.state = {
      errorAlert: null,
      progress: null,
      fileUpload: null,
      dialogAppear: false,
      editProductAlert: null,
      uploadProductAlert: null,
      total_record: 0,
      loading: true,
      addEditFormAppear: false,
      editForm: null,
      apiResponseExportCsv: null,
      apiResponseMessagePopup: null,
      deleteProductPopup: null,

      rows: [],
      filtration: {
        start: 0,
        limit: 30,
        orderByKey: "created_at_local",
        order: "desc",
        searchKey: "",
        checkedProduct: [],
        searchValueTypedOnly: "",
        searchValue: "",
        sorting: "created_at_local-DESC",
        quickFilter: [],
        checkedProductNumber: [],
        isAllChecked: false,
        deleteProductPopup: false,
      },
      Product: { productDetailsTab: 0 },
    };
    this.ApiRequest = this.ApiRequest.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.inputSearch = this.inputSearch.bind(this);
    this.StartLoader = this.StartLoader.bind(this);
    this.getPagination = this.getPagination.bind(this);
    this.CSVfileUpload = this.CSVfileUpload.bind(this);
    this.DownloadCSV = this.DownloadCSV.bind(this);
    this.DatagridNoRows = this.DatagridNoRows.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.CheckboxSelection = this.CheckboxSelection.bind(this);
    this.quickFilters = this.quickFilters.bind(this);
    this.DeleteProduct = this.DeleteProduct.bind(this);

    this.SearchBox = this.SearchBox.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }
  SearchBox = () => {
    return (
      <SearchBarHelper
        onClickSearchButton={this.inputSearch}
        value={this.state.filtration.searchValueTypedOnly}
        placeholder={
          "Search for a product code, product title, or product description..."
        }
        onChange={(event) => {
          const { value } = event.target;
          let filtration = this.state.filtration;
          filtration.searchValueTypedOnly = value;
          this.setState({ filtration: filtration, errorAlert: null });
        }}
        onSubmit={() => {
          let filtration = this.state.filtration;
          filtration.start = 0;

          filtration.searchValue = filtration.searchValueTypedOnly;
          this.setState({ filtration: filtration, errorAlert: null });
          this.StartLoader();
          this.ApiRequest();
        }}
      />
    );
  };
  clearFilter = (request = true) => {
    let filtration = {
      start: 0,
      limit: 30,
      orderByKey: "created_at_local",
      order: "desc",
      searchKey: "",
      checkedProduct: [],
      searchValueTypedOnly: "",
      searchValue: "",
      sorting: "created_at_local-DESC",
      quickFilter: [],
      checkedProductNumber: [],
      isAllChecked: false,
      deleteProductPopup: false,
    };
    this.setState({ filtration: filtration, errorAlert: null });
    if (request) {
      this.StartLoader();
      this.ApiRequest();
    }
  };
  CheckboxSelection = (params) => {
    if (params.length > 0) {
      this.props.setFooterMargin(50);

      let products_no = [];

      let status_id = 0;
      this.state.rows.forEach((row) => {
        if (params.includes(row.id)) {
          products_no.push(row.product_code);
          if (row.status_id > status_id) status_id = row.status_id;
        }
      });
    } else {
      this.props.setFooterMargin(0);
    }
  };

  DatagridNoRows = () => {
    const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
      flexDirection: "column",
      "& .ant-empty-img-1": {
        fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
      },
      "& .ant-empty-img-2": {
        fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
      },
      "& .ant-empty-img-3": {
        fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
      },
      "& .ant-empty-img-4": {
        fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
      },
      "& .ant-empty-img-5": {
        fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
        fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
      },
    }));
    return (
      <StyledGridOverlay>
        <img src="images/EmptyBox.png" width="100px" />
        <Box sx={{ mt: 1 }}>No Products</Box>
      </StyledGridOverlay>
    );
  };
  // Call ApiRequest Datatable Load
  ApiRequest = () => {
    (async () => {
      let URL = `?start=${this.state.filtration.start}&limit=${
        this.state.filtration.limit
      }&orderByKey=${this.state.filtration.orderByKey}&order=${
        this.state.filtration.order
      }&searchValue=${
        this.state.filtration.searchValue
      }&quick_filter=${encodeURI(this.state.filtration.quickFilter)}`;

      APIService.GET(AppSettings.GetProductAPI + URL)
        .then((response) => response.json())
        .then((response) => {
          if (response.success == true) {
            if (!this.state.filtration.searchValue)
              document.getElementById("total-product-count-nav").innerHTML =
                response.result.total_record;
            this.setState({
              total_record: response.result.total_record,
              rows: response.result.data,
            });
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              errorAlert: <ApiResponseError response={response} />,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
            loading: false,
          });
        });
    })();
  };

  // On Page change
  handlePageChange = (start) => {
    let filtration = this.state.filtration;
    filtration.start = start;
    filtration.checkedProductNumber = [];
    filtration.isAllChecked = false;
    filtration.checkedProduct = [];
    this.setState({ filtration: filtration, errorAlert: null });
    this.CheckboxSelection(this.state.filtration.checkedProduct);

    this.StartLoader();
    this.ApiRequest();
  };

  getPagination = () => {
    if (this.state.total_record % this.state.filtration.limit == 0)
      return this.state.total_record / this.state.filtration.limit;
    else return this.state.total_record / this.state.filtration.limit + 1;
  };

  // Start Loader And Empty Table
  StartLoader = (is_empty_datatable = true) => {
    if (is_empty_datatable) this.setState({ rows: [], loading: true });
    else this.setState({ loading: true });
  };
  // Call when refresh the page
  componentDidMount() {
    this.StartLoader();
    this.ApiRequest();
  }

  handlePageSizeChange = (params) => {
    let filtration = this.state.filtration;
    filtration.start = 0;
    filtration.limit = params.target.value;
    this.setState({ filtration: filtration, errorAlert: null });

    this.StartLoader();
    this.ApiRequest();
  };
  inputSearch = () => {
    this.setState({ rows: [], loading: true });
    // this.setState({ rows: [], loading: true });

    (async () => {
      let rows = [];
      for (var i = 0; i < this.state.rowsCopy.length; i++) {
        let depot_name = this.state.rowsCopy[i].depot_name.toLowerCase();
        let depot_postcode =
          this.state.rowsCopy[i].depot_postcode.toLowerCase();
        if (
          depot_postcode.search(this.state.serchKey.toLowerCase()) != -1 ||
          depot_name.search(this.state.serchKey.toLowerCase()) != -1
        ) {
          rows.push(this.state.rowsCopy[i]);
        }
      }
      this.setState({ rows: rows });
      this.setState({
        loading: false,
      });
    })();
  };

  // On Page change
  DownloadCSV = () => {
    (async () => {
      APIService.POST(AppSettings.DownloadProductCSVAPI)
        .then((response) => response.blob())
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Products.csv");
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        })
        .catch(() => {
          this.setState({
            errorAlert: (
              <ApiResponseError
                response={{
                  message:
                    process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
                }}
              />
            ),
          });
        });
    })();
  };

  CSVfileUpload(event) {
    event.preventDefault();
    if (this.state.fileUpload == "" || this.state.fileUpload == null) {
      this.setState({
        progress: false,
        uploadProductAlert: <Alert severity="error">File is empty!!</Alert>,
      });
      return 0;
    }

    this.setState({ progress: <CircularProgress className="progressBar" /> });

    var formdata = new FormData();
    formdata.append("product", this.state.fileUpload);

    APIService.FormPOST(AppSettings.UploardProductCSVAPI, formdata)
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          document.getElementById("csv-upload").value = "";

          this.setState({
            progress: false,
            fileUpload: null,
            apiResponseExportCsv: (
              <OrderActionResponsePopupHelper
                handleCloseAlertDialog={() => {}}
                result={response.result}
                refreshData={() => {
                  this.setState({ apiResponseExportCsv: null });
                  this.StartLoader();
                  this.ApiRequest();
                }}
              />
            ),
            // apiResponseMessagePopup: response,
          });
        } else {
          this.setState({
            progress: false,
            apiResponseMessagePopup: response,
          });
        }
      })
      .catch(() => {
        this.setState({
          errorAlert: (
            <ApiResponseError
              response={{
                message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
              }}
            />
          ),
        });
      });
  }

  DeleteProduct(ids) {
    this.setState({ progress: true });
    APIService.POST(AppSettings.ProductDelete, {
      ids: ids,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success == true) {
          this.setState({
            progress: false,
            apiResponseMessagePopup: response,
          });

          this.StartLoader();
          this.ApiRequest();
        } else {
          this.setState({
            progress: false,
            apiResponseMessagePopup: response,
          });
        }
      })
      .catch(() => {
        this.setState({
          errorAlert: (
            <ApiResponseError
              response={{
                message: process.env.REACT_APP_DEFAULT_INTERNAL_SERVER_MESSAGE,
              }}
            />
          ),
        });
      });
  }

  // On Page change
  quickFilters = (event, value, name) => {
    let filtration = this.state.filtration;
    if (value) filtration.quickFilter.push(name);
    else {
      let index = filtration.quickFilter.indexOf(name);
      if (index > -1) {
        filtration.quickFilter.splice(index, 1);
      }
    }
    filtration.start = 0;
    this.setState({ filtration: filtration, errorAlert: null });
    this.StartLoader();
    this.ApiRequest();
  };
  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.progress && <LoadingHelper />}
        {this.state.apiResponseExportCsv}
        {this.state.apiResponseMessagePopup && (
          <APIResponsePopupHelper
            result={this.state.apiResponseMessagePopup}
            successClose={() => {
              this.setState({ apiResponseMessagePopup: false });
            }}
            failureClose={() => {
              this.setState({ apiResponseMessagePopup: false });
            }}
          />
        )}
        {this.state.addEditFormAppear && (
          <AddEditProduct
            editDate={this.state.editForm}
            onClose={() => {
              this.setState({ addEditFormAppear: false, editForm: null });
              this.StartLoader();
              this.ApiRequest();
            }}
          />
        )}
        {this.state.filtration.deleteProductPopup && (
          <ConfirmationPopup
            onClose={() => {
              let filtration = this.state.filtration;
              filtration.deleteProductPopup = false;
              this.setState({ filtration: filtration, errorAlert: null });
            }}
            onSubmit={() => {
              this.DeleteProduct(this.state.filtration.checkedProduct);
              let filtration = this.state.filtration;
              filtration.deleteProductPopup = false;
              filtration.checkedProductNumber = [];
              filtration.isAllChecked = false;
              filtration.checkedProduct = [];
              this.setState({ filtration: filtration, errorAlert: null });
            }}
            OrderOrProduct={this.state.filtration.checkedProductNumber}
            heading={"Delete Product"}
            message={"You are about to delete product the following products:"}
            icon={<DeleteOrdersIcon className="delete-icon" />}
          />
        )}
        {this.state.deleteProductPopup && (
          <ConfirmationPopup
            onClose={() => {
              this.setState({ deleteProductPopup: null });
            }}
            onSubmit={() => {
              this.DeleteProduct([this.state.deleteProductPopup.id]);
              this.setState({ deleteProductPopup: null });
            }}
            OrderOrProduct={[this.state.deleteProductPopup.product_code]}
            heading={"Delete Product"}
            message={"You are about to delete product the following products:"}
            icon={<DeleteOrdersIcon className="delete-icon" />}
          />
        )}
        {this.state.errorAlert}
        <Grid container spacing={3} id="product-page">
          <Grid item xs={12} sm={12} md={3} className="page-title-grid">
            <h1 className="pageTitle">PRODUCTS</h1>
            <div className="search-box-mobile">{this.SearchBox()}</div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            className="buttons-add-export-import"
          ></Grid>
          <Grid item xs={12} sm={12} md={2} className="search-box-grid">
            {this.SearchBox()}
          </Grid>
          <Grid className="quick-filter" md={12} container>
            <Grid item md={8} className="quick-filter-md-9 pl-8">
              <FormControlLabel
                className="QuickFiltration-heading ml-10"
                control={<Checkbox className="d-none" />}
                label="Filters"
              />
              <div className="quick-filter-checkbox">
                <CheckBoxHelper
                  id="quick-filter-recently-added"
                  onChange={(event, newVal) => {
                    let filtration = this.state.filtration;
                    filtration.start = 0;
                    filtration.orderByKey = newVal ? "created_at_local" : "";

                    this.setState({ filtration: filtration, errorAlert: null });
                    this.StartLoader();
                    this.ApiRequest();
                  }}
                  checked={
                    this.state.filtration.orderByKey == "created_at_local"
                      ? true
                      : false
                  }
                />
                <label htmlFor="quick-filter-recently-added">
                  {" "}
                  Recently Added
                </label>
              </div>
              <div className="quick-filter-checkbox">
                <CheckBoxHelper
                  id="quick-filter-recently-edited"
                  onChange={(event, newVal) => {
                    let filtration = this.state.filtration;
                    filtration.start = 0;
                    filtration.orderByKey = newVal ? "updated_at_local" : "";

                    this.setState({ filtration: filtration, errorAlert: null });
                    this.StartLoader();
                    this.ApiRequest();
                  }}
                  checked={
                    this.state.filtration.orderByKey == "updated_at_local"
                      ? true
                      : false
                  }
                />
                <label htmlFor="quick-filter-recently-edited">
                  {" "}
                  Recently Edited
                </label>
              </div>
              <div className="quick-filter-checkbox">
                <CheckBoxHelper
                  id="quick-filter-delete-product"
                  onChange={(event, newVal) => {
                    this.quickFilters(event, newVal, "deleted");
                  }}
                  checked={
                    this.state.filtration.quickFilter.includes("deleted") ===
                    true
                      ? true
                      : false
                  }
                />
                <label htmlFor="quick-filter-delete-product"> Deleted</label>
              </div>
            </Grid>

            <Grid item md={4} className={"quick-filter-md-3"}>
              <FormGroup row>
                <FormLabel component="legend" className="per-page">
                  Per page
                </FormLabel>
                <Select
                  variant="outlined"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className="PageNo"
                  IconComponent={() => (
                    <ExpandMoreIcon className="dropdown-arrow  MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  value={this.state.filtration.limit}
                  onChange={this.handlePageSizeChange}
                >
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={90}>90</MenuItem>
                  <MenuItem value={120}>120</MenuItem>
                </Select>
                <SortByIcon />
                <Select
                  className="PageNo sorting-select max-width-120"
                  variant="outlined"
                  IconComponent={() => (
                    <ExpandMoreIcon className="dropdown-arrow  MuiSelect-icon MuiSelect-iconOutlined" />
                  )}
                  value={this.state.filtration.order}
                  onChange={(params) => {
                    let filtration = this.state.filtration;
                    filtration.start = 0;
                    filtration.order = params.target.value;

                    // if (filtration.sorting.match(/-ASC/g)) {
                    //   filtration.orderByKey = filtration.sorting.replace(
                    //     "-ASC",
                    //     ""
                    //   );
                    //   filtration.order = "ASC";
                    // } else {
                    //   filtration.orderByKey = filtration.sorting.replace(
                    //     "-DESC",
                    //     ""
                    //   );
                    //   filtration.order = "DESC";
                    // }

                    this.setState({ filtration: filtration, errorAlert: null });
                    this.StartLoader();
                    this.ApiRequest();
                  }}
                >
                  <MenuItem className="sorting" value={"asc"}>
                    Latest&nbsp;
                    <img src="images/ascending-sort.png" />
                  </MenuItem>
                  <MenuItem className="sorting" value={"desc"}>
                    Latest&nbsp;
                    <img src="images/descending-sort.png" />
                  </MenuItem>
                </Select>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className="">
              <Typography className="record-count">
                {this.state.total_record}{" "}
                {this.state.total_record < 1 ? "Result" : "Results"}
              </Typography>

              {this.state.filtration.searchValue != "" && (
                <>
                  <a
                    color="primary"
                    className={"btn-clear"}
                    onClick={() => {
                      let filtration = this.state.filtration;

                      filtration.searchValue = "";
                      filtration.searchValueTypedOnly = "";
                      this.setState({
                        filtration: filtration,
                        errorAlert: null,
                      });
                      this.StartLoader();
                      this.ApiRequest();
                    }}
                  >
                    Clear Search
                  </a>
                  <span className={"btn-search-value"}>
                    Searched Key - {this.state.filtration.searchValue}
                  </span>
                </>
              )}
              {(this.state.filtration.quickFilter.length > 0 ||
                this.state.actionButtonValue) && (
                <a
                  color="primary"
                  className={"btn-clear"}
                  onClick={this.clearFilter}
                >
                  Clear Filters
                </a>
              )}
            </Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.total_record}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>
          <Grid item md={12}>
            <TableContainer
              classes={{ root: classes.customTableContainer }}
              className="product-table"
            >
              <Table stickyHeader>
                <TableHead className="">
                  <TableRow>
                    <TableCell>
                      <CheckBoxHelper
                        onChange={(event, newVal) => {
                          let filtration = this.state.filtration;

                          if (newVal) {
                            filtration.checkedProduct = [];
                            filtration.checkedProductNumber = [];
                            !this.state.loading &&
                              this.state.rows.length > 0 &&
                              this.state.rows.map((row) => {
                                filtration.checkedProduct.push(row.id);

                                filtration.checkedProductNumber.push(
                                  row.product_code
                                );
                              });
                            filtration.isAllChecked = true;
                          } else {
                            filtration.isAllChecked = false;
                            filtration.checkedProduct = [];
                            filtration.checkedProductNumber = [];
                          }
                          this.setState({ filtration: filtration });
                          this.CheckboxSelection(
                            this.state.filtration.checkedProduct
                          );
                        }}
                        checked={this.state.filtration.isAllChecked}
                      />
                    </TableCell>

                    <TableCell />
                    <TableCell>PRODUCT CODE</TableCell>
                    <TableCell>PRODUCT TITLE</TableCell>
                    <TableCell>BOX DIMENSIONS</TableCell>
                    <TableCell>CBM</TableCell>
                    <TableCell>WEIGHT</TableCell>
                    <TableCell>ASSEMBLY TIME</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          this.StartLoader();
                          this.setState({ errorAlert: null, loading: true });
                          this.ApiRequest();
                        }}
                      >
                        <AutorenewIcon
                          style={{
                            color: "white",
                            justifyContent: "space-around",
                          }}
                          className={
                            this.state.loading === true ? " rotating " : "  "
                          }
                        />
                        <span>
                          {this.state.loading === true
                            ? " Refreshing Data"
                            : " Refresh "}
                        </span>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state.loading &&
                    this.state.rows.length > 0 &&
                    this.state.rows.map((row) => (
                      <TableRow
                        key={row.id}
                        className={
                          this.state.filtration.checkedProduct.includes(
                            row.id
                          ) === true
                            ? "active"
                            : ""
                        }
                      >
                        <TableCell>
                          <CheckBoxHelper
                            onChange={(event, newVal) => {
                              let filtration = this.state.filtration;
                              if (newVal) {
                                filtration.checkedProduct.push(row.id);

                                filtration.checkedProductNumber.push(
                                  row.product_code
                                );
                              } else {
                                const index = filtration.checkedProduct.indexOf(
                                  row.id
                                );
                                const index2 =
                                  filtration.checkedProductNumber.indexOf(
                                    row.product_code
                                  );
                                if (index > -1) {
                                  filtration.checkedProduct.splice(index, 1); // 2nd parameter means
                                }
                                if (index2 > -1) {
                                  filtration.checkedProductNumber.splice(
                                    index2,
                                    1
                                  ); // 2nd parameter means remove one item only
                                }
                              }
                              if (!this.state.loading) {
                                if (this.state.rows.length > 0) {
                                  filtration.isAllChecked =
                                    this.state.rows.length ==
                                    filtration.checkedProduct.length;
                                }
                              }

                              this.setState({ filtration: filtration });
                              this.CheckboxSelection(
                                this.state.filtration.checkedProduct
                              );
                            }}
                            checked={
                              this.state.filtration.checkedProduct.includes(
                                row.id
                              ) === true
                                ? true
                                : false
                            }
                          />
                        </TableCell>
                        <TableCell className="image-td">
                          <img
                            src={
                              row.img_url && row.img_url.includes(",")
                                ? row.img_url.split(",")[0]
                                : row.img_url
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/images/placeholder.jpg";
                            }}
                          />
                        </TableCell>
                        <TableCell>{row.product_code}</TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>
                          <table className="dimensions">
                            <tr>
                              <th>W:</th>
                              <td>
                                {row.height != "" &&
                                row.height != "null" &&
                                row.height != null
                                  ? row.height + "m"
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>H:</th>
                              <td>
                                {row.width != "" &&
                                row.width != "null" &&
                                row.width != null
                                  ? row.width + "m"
                                  : "N/A"}
                              </td>
                            </tr>
                            <tr>
                              <th>D:</th>
                              <td>
                                {row.length != "" &&
                                row.length != "null" &&
                                row.length != null
                                  ? row.length + "m"
                                  : "N/A"}
                              </td>
                            </tr>
                          </table>
                        </TableCell>
                        <TableCell>
                          {row.cbm != "" && row.cbm != "null" && row.cbm != null
                            ? row.cbm + "m"
                            : "N/A"}
                        </TableCell>
                        <TableCell>
                          {typeof row.weight != "object" && row.weight != ""
                            ? row.weight + "kg"
                            : "N/A"}
                        </TableCell>
                        <TableCell className={"assembly-build"}>
                          {typeof row.assembly_build != "object" &&
                            row.assembly_build != "" && <AssemblyIcon />}{" "}
                          {typeof row.assembly_build != "object" &&
                          row.assembly_build != ""
                            ? row.assembly_build + " min"
                            : "N/A"}
                        </TableCell>
                        {!row.deleted_at && (
                          <TableCell>
                            <EditDeleteDropdown
                              is_shopify_updated={
                                !row.created_at_shopify
                                  ? true
                                  : row.is_shopify_updated == 1
                                  ? true
                                  : false
                              }
                              onEditClick={() => {
                                this.setState({
                                  addEditFormAppear: true,
                                  editForm: row,
                                });
                              }}
                              onDeleteClick={() => {
                                this.setState({
                                  deleteProductPopup: row,
                                });
                              }}
                            />
                          </TableCell>
                        )}
                        {row.deleted_at && (
                          <TableCell style={{ textAlign: "right" }}>
                            DELETED
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  {this.state.loading && (
                    <>
                      {Array.from(new Array(10)).map((val, index) => (
                        <TableRow key={index}>
                          <TableCell colSpan={12}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              height={40}
                              width={"100%"}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                  {!this.state.loading && this.state.rows.length == 0 && (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <NoResultHelper />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            className="mr-8 ml-8 count-pagination-searched-result"
            container
          >
            <Grid item md={8} className=""></Grid>
            <Grid item md={4} className={"pagination"}>
              <Pagination
                count={this.state.total_record}
                start={this.state.filtration.start}
                limit={this.state.filtration.limit}
                handlePageChange={this.handlePageChange}
              />
            </Grid>
          </Grid>
          {this.state.filtration.checkedProduct.length != 0 && (
            <Grid
              container
              spacing={3}
              className="count-pagination-searched-result multi-order-action"
            >
              <Grid item xs={12} sm={12} md={3}>
                <Typography className="record-count">
                  <span>{this.state.filtration.checkedProduct.length} </span>
                  {this.state.filtration.checkedProduct.length < 1
                    ? "Product"
                    : "Products"}{" "}
                  Selected
                </Typography>
                <a
                  color="primary"
                  className={"btn-clear"}
                  onClick={() => {
                    let filtration = this.state.filtration;

                    filtration.isAllChecked = false;
                    filtration.checkedProduct = [];
                    this.setState({ filtration: filtration, errorAlert: null });
                    this.CheckboxSelection([]);
                  }}
                >
                  Clear Selection
                </a>
              </Grid>
              <Grid item xs={12} sm={12} md={9} className="order-action">
                <Typography component="span" className="order-action-text">
                  Product Actions:
                </Typography>
                <Button
                  className="order-action-button delete"
                  onClick={() => {
                    let filtration = this.state.filtration;
                    filtration.deleteProductPopup = true;
                    this.setState({ filtration: filtration, errorAlert: null });
                  }}
                >
                  <DeleteOrdersIcon /> Delete
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Dialog
          className="import-csv-product"
          open={this.state.dialogAppear}
          onClose={() => {
            this.setState({ dialogAppear: false });
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Import Product CSV</DialogTitle>
          <DialogContent>
            <form noValidate autoComplete="off" className="mb-0">
              {this.state.uploadProductAlert && (
                <Grid container spacing={3} className="mb-20">
                  <Grid item xs={12} sm={12} md={12}>
                    {this.state.uploadProductAlert}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="p"
                  component="h6"
                  fullWidth
                  className="UploardCSV"
                >
                  Upload CSV
                </Typography>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  type="file"
                  id={"csv-upload"}
                  name="product"
                  accept=".csv"
                  // value={()=>}
                  onChange={(event) => {
                    this.setState({ fileUpload: event.target.files[0] });
                  }}
                  onClick={() => {
                    this.setState({ uploadProductAlert: null });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}></Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              onClick={this.CSVfileUpload}
              endIcon={this.state.progress}
              className="btn-black m-0"
            >
              Import
            </Button>
            <Button
              onClick={() => {
                this.setState({ dialogAppear: false });
              }}
              variant="outlined"
              className="btn-close"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ProductsPage);
